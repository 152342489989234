.slider-headliner {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.container__slider {
  margin-bottom: 24px;
  margin-right: 0px;
}
.container__slider .slick-slide .slick-active .slick-current {
  margin-right: 10px;
}

.arrows-container {
  display: flex;
  gap: 16px;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: 1px solid #B4BDC3;
}

.tablet {
  margin-left: 120px;
}

.mobile {
  margin-left: 51px;
  margin-top: 10px;
}