.product-variants-actions {
  display: flex;
  flex-direction: column;
}

.product-variants-actions_container-1 {
  display: flex;
}

.available-colors {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.available-colors_icons-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.available-colors_icon-button {
  border: 4px;
  outline: 1px;
}

.available-colors_circle-icon {
  border: 1px solid;
  border-radius: 50%;
}

.available-capacity {
  display: flex;
  gap: 12px;
}

.available-capacity_button {
  height: 32px;
  font-size: 14px;
  font-weight: 500;
}

.prices {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.prices_regular-price {
  font-weight: 500;
  line-height: 28px;
  text-decoration: line-through;
  align-self: center;
}

.buttons_add-button {
  height: 48px;
  font-size: 14px;
}

.product-info {
  width: 100%;
  height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-info_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
