@font-face {
    font-family: Mont;
    font-weight: 700;
    src: url('../public/fonts/Mont-Bold.otf');
    font-display: swap;
}
@font-face {
    font-family: Mont;
    font-weight: 600;
    src: url('../public/fonts/Mont-SemiBold.otf');
    font-display: swap;
}
@font-face {
    font-family: Mont;
    font-weight: 500;
    src: url('../public/fonts/Mont-Regular.otf');
    font-display: swap;
}

.Mont-font {
    font-family: Mont;
}

body {
    font-family: Mont;
}